import * as Sentry from "@sentry/browser";
import { clearLocalStorageInHostedModule, subscribeToIframeMessages } from "./iframe-synchronization";
import { displayCore } from "../main";
import { TokensStore } from "../tokens-store";
import { classicStore } from "./classic-store";
import { redirectToLogin } from "../redirect-to-login";

export function initClassic(element: HTMLElement) {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: "https://glet_b39664da97f33b28da7f1f853b4a2be2@observe.gitlab.com:443/errortracking/api/v1/projects/48216543",
    });
  }

  const tokensStore: TokensStore = {
    getTokens: () => classicStore.userModuleInfo.get(),
    tokensChanged() {

    },
    handle401() {
      classicStore.userModuleInfo.remove();

      redirectToLogin();
    },
    clearTokens() {
      classicStore.userModuleInfo.remove()

      clearLocalStorageInHostedModule();
    },
  };

  displayCore(element, 'classic', tokensStore, '', 'CORE');

  subscribeToIframeMessages();
}
